import React from "react";


const LeanTransformation = () => {

    return (
        <div className="mt-[50px] p-10">
            <div className="container mx-auto">
                <img src="https://i.ibb.co/16RFXBm/Mindworksbd-Lean-Transformation.jpg" alt="Mind works Pdf"
                     className="w-[650px] block mx-auto"/>
            </div>
        </div>
    )
};

export default LeanTransformation;
