import React from 'react';
import SectionBanner from '../SectionBanner/SectionBanner';

const AboutBanner = () => {
    return (
        <div>
            <SectionBanner backgroundImage={"https://i.ibb.co/PZTnWhZ/about.png"} title={"About Us"}/>
        </div>
    );
};

export default AboutBanner;