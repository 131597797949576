import mechanicalImage from "../../src/assets/whatWeDo/Engineer.png"
import electricalImage from "../../src/assets/whatWeDo/Install.png"
import plumbingImage from "../../src/assets/whatWeDo/Maintain.png"

export const whatWeDo = [
    {
        id : 1,
        name : 'Mechanical',
        img : mechanicalImage,
        description : "We have comprehensive resources to support the engineering of your project with capabilities in computer aided design, mechanical and electrical engineering."
    },
    {
        id : 2,
        name : 'Electrical',
        img : electricalImage,
        description : "We have dedicated teams to support you in delivering quality through the project management, installation and commissioning of your project."
    },
    {
        
        id : 3,
        name : 'Plumbing',
        img : plumbingImage,
        description : "We value the provision of reliable maintenance services for your power generation equipment delivered efficiently through our extensive network of service engineers."
    }
]